require('intersection-observer');
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import lazySizes from 'lazysizes';
import Headroom from 'headroom.js';
import Accordion from 'accordion-js';
import 'slick-carousel';
import prettyDropdown from 'pretty-dropdowns';
import 'magnific-popup';
import L from 'leaflet';
import isInViewport from './helper/isInViewport';
import once from './helper/once';
import _ from 'lodash';
// import TweenMax from 'TweenMax'
// import EasePack from 'EasePack'
// import ScrollMagic from 'ScrollMagic'
// import 'animation.gsap'
import $ from 'jquery';
import sal from 'sal.js';
import './lib/webp-detector';

lazySizes.cfg.loadMode = 2;
lazySizes.cfg.expand = 1000;

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initHeadroom();
    this.initSal();
    this.initNavInsert();
    this.initAccordion();
    this.playVideos();
    this.initDropdown();
    this.initHomePageAnimation();
    this.hoverTileWhenOnViewport();
    this.changeMobileMenuAnchorAttributes();
    this.setDynamicVH();
    this.deferLoadingUntilLazyloaded();
    this.handleModalGridOverlay();
    if(!wp_php_vars.isFrontPage) {
      if ( $('.hero').length ) { $(window).scroll(() => this.heroScrollTrack() ) }
    }
    if ( $('#map').length ) this.map()
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
    this.$logo_active = $('.js-logo');
    this.$logo = $('.js-logo-main');
  },
  events() {
    this.$doc.on(
      'click',
      '.js-hamburger',
      event => this.showMobileNav(event)
    );
    this.$doc.on(
      'click',
      '.js-share',
      event => this.openShareWindow(event)
    );
    this.$doc.on (
      'click',
      'nav a',
      event => this.handlePageNavClick(event)
    )
    this.$doc.on(
      'click',
      '.js-mobile-nav .menu-item-has-children > a',
      event => this.handleChildren(event)
    )
    this.$doc.on(
      'click',
      '.js-modal-grid-col',
      event => this.handleModal(event)
    )
    this.$doc.on (
      'click',
      '.js-modal-close',
      event => $.magnificPopup.close()
    )
    this.$doc.on(
      'click',
      '.contact-nav a',
      event => this.navigateToContact(event)
    )
    this.$doc.on(
      'click',
      '.hero .arrow-anchor',
      event => this.handleHeroArrow(event)
    )
    this.$doc.on(
      'click',
      '.philosophy-wrapper .js-title-trigger',
      event => this.handleTileDescription(event)
    )
    this.$doc.on(
      'click',
      '.js-mobile-desc',
      event => this.handleTileDescription(event)
    )

    $('.nav--main li.menu-item-has-children').on(
      'mouseenter',
      event => this.handleSubmenuIn(event)
    )
    $('.nav--main li.menu-item-has-children').on(
      'mouseleave',
      event => this.handleSubmenuOut(event)
    )
  },
  playVideos() {
    $('.hero__video video').length && $('.hero__video video')[0].play()
    $('.full-media__video video').length && $('.full-media__video video')[0].play()
  },

  handleTileDescription(event) {
    let thisClickedTitleItem = $(event.currentTarget);
    let thisPlusSign = thisClickedTitleItem.find('.u-plus-sign');
    let thisMinusSign = thisClickedTitleItem.find('.u-minus-sign');
    let thisHoverDescriptionItem = thisClickedTitleItem.next('[class*=--hover-desc]');

    if (thisHoverDescriptionItem.css('opacity') == 1 ){
      thisHoverDescriptionItem.css('opacity', 0);
      thisPlusSign.css('opacity', 1);
      thisMinusSign.css('opacity', 0);
    } else {
      thisHoverDescriptionItem.css('opacity', 1);
      thisPlusSign.css('opacity', 0);
      thisMinusSign.css('opacity', 1);
    }
  },

  changeMobileMenuAnchorAttributes(e) {
    $('.js-mobile-nav').find('.menu-item-has-children').find('> a').attr('href', '##');
  },

  /**
   * Sets vh thru javascript dymamically because css' vh doesn't work well on mobile.
   */
  setDynamicVH () {
    if ( this.$win.width() <= 960 ) {
      // Run on first page load.
      setDynamicVHinner();

      let width = this.$win.width();
      // Change vh height only if width changes.
      this.$win.on('resize', _.debounce(function() {
        if ($(this).width() !== width) {
          width = $(this).width();
          // We execute the same script as before
          setDynamicVHinner();
          console.log(width);
        }
      }, 100));

      function setDynamicVHinner() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
    }
  },

  handleSubmenuIn(e) {

    let menuParentHeight = $('.menu-item-has-children').innerHeight();
    let subMenuTop = $('.nav--main ul.sub-menu').length ? menuParentHeight/2 + $('.current_page').innerHeight()  : menuParentHeight/2 ;

    $('.header').addClass('extend')

    if($(e.currentTarget).hasClass('current_page_parent_enabled')) {
      $('.nav--main .current_page_parent_enabled ul.sub-menu').css('top', `${subMenuTop}px`)
    } else {
      $('.nav--main ul.sub-menu').css('top', '12px')
    }

  },
  handleSubmenuOut(e) {
    console.log('handle submenu out called');

    const menuParentHeight = $('.menu-item-has-children').innerHeight() + 10;

    $('.header').removeClass('extend')
    $('.nav--main ul.sub-menu').css('top', menuParentHeight/2)
    $('.current_page').removeClass('hidden')

  },
  navigateToContact(e) {
    e.preventDefault()

    var target = $('footer');
    var target_top = target ? target.offset().top : null;

    $('html,body').animate({
      scrollTop: target_top
      }, 750);
    return false;

  },
  heroScrollTrack() {
    let opacity_early = ($(window).scrollTop() / ($(window).height() - 250 ) );
    let opacity = ($(window).scrollTop() / ($(window).height() ) );

    if ( $('.hero__video').length ) {
      $('.hero__video').css('opacity', (1 - opacity) )
    }


    if ( $(window).width() >= 960 ) {
      $('.hero .arrow-anchor').css('opacity', (1 - opacity_early) )
      $('.hero .hero__content').css('opacity', (1 - opacity_early) )
    }
  },
  handleHeroArrow(e) {
    e.preventDefault()
    var target = $('.building-block.hero + .building-block');
    var target_top = target ? target.offset().top : null;

    if (target.length && e.target == e.currentTarget) {

      $('html,body').animate({
        scrollTop: target_top
        }, 500);
      return false;
    }
  },

  handleModal(event) {
    event.preventDefault();

    const {target} = event
    const parent = $(target).attr('modal-trigger') ? target : $(target).closest('[modal-trigger]');
    const src = $(parent).find('+ .modal__content');
    const type = 'inline'

    $.magnificPopup.open({
      items: { src, type },
      fixedContentPos: false,
      callbacks : {
        beforeOpen: function() {
          window.clientScrollPos = document.documentElement.scrollTop;
          $('body').addClass('mfp-active');
        },
        beforeClose: function() {
          $('body').removeClass('mfp-active');
          window.scrollTo(0, window.clientScrollPos);
        },
        open : () => {
          $('html').css({
            "margin-right" : "0", // magnific popup sets a margin-right of 17 px for some reason, removing because it messes with the modal scrollbar.
            "overflow-y" : "hidden"
          });
        },
        close : () => {
          $('html').css({ "overflow" : "initial" });
        }
      }
    });
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  handleChildren(e) {
    const {target} = e;
    const submenu = $(target).find('+ .sub-menu');
    e.preventDefault();

    submenu.css('height', 0)

    $(target).toggleClass('is-open');

    if ( $(target).hasClass('is-open') ) {
      submenu.css('display', 'block')

      setTimeout(() => {
        submenu.css('height', submenu.data('height'))
      }, 50)

    } else {
      submenu.css('height', 0)
    }
  },
  handlePageNavClick(e) {
    var {href} = e.currentTarget;
    var currURL = new URL(window.location.href);
    var navURL = new URL (href);

    if (href.includes('#') && currURL.pathname.replace(/\//g, "") == navURL.pathname.replace(/\//g, "")) {
      e.preventDefault();
    } else {
      window.location.href = navURL;
      return false;
    }

  },
  initParallax() {
//   let count = 0;
//   $('section').each(function() {
//     count++;
//     $(this).wrap(`<div class="animated-scroll-${count} anim-section" />`)
//   })
//   const { Linear } = EasePack;
// console.log( EasePack )
//   const options = {
//     globalSceneOptions: {
//       triggerHook: "onEnter",
//       duration: "200%"
//     }
//   }
//   let anim_options = {
//     y: "80%",
//     ease: Linear.easeIn
//   }
//   var controller = new ScrollMagic.Controller(options);

//   new ScrollMagic.Scene({triggerElement: `.animated-scroll-2`})
//   .setTween(`.animated-scroll-2 > div`, anim_options)
//   .addTo(controller);

  },

  initSliders() {

    // Liberates user's scrollbar before animation runs completely.
    $('.js-slides').on('init', function(){
      $('html').removeClass('homepage-animation--before-slider-intialized');
    });

    const sliderOptions = {
      infinite: true,
      slidesToShow: 1,
      draggable: true,
      pauseOnHover: false,
      // autoplay: true, // This gets set to true with setOption
      autoplaySpeed: 3000,
      arrows: false,
      dots: false,
      fade: true,
      speed: 850,
      cssEase: 'ease-in-out',
      touchThreshold: 100,
    }

    $('.js-slides').slick(sliderOptions);
  },
  initHeadroom() {
    const nav_style = wp_php_vars ? wp_php_vars.nav_style : ''
    let theHeader = document.querySelector('.js-header');
    let headerStyleOnTop = nav_style != 'dark' ? 'header-style__on-top' : '';
    const config = {
      // offset : 100,
      // tolerance : 5,
      // css classes to apply
      classes : {
        // when element is initialised
        initial : "headroom",
        // when scrolling up
        pinned : "headroom--pinned",
        // when scrolling down
        unpinned : 'headroom--unpinned',
        // when above offset
        top : headerStyleOnTop,
        // when below offset
        notTop : "header-style__" + nav_style,
        // when at bottom of scoll area
        bottom : "headroom--bottom",
        // when not at bottom of scroll area
        notBottom : "headroom--not-bottom",
        // when frozen method has been called
        frozen: "headroom--frozen",
        // multiple classes are also supported with a space-separated list
        // pinned: "headroom--pinned foo bar"
  },
    }
    let headroom = new Headroom(theHeader, config);
    headroom.init();

    if ( window.location.href.includes('#') ) {
      $('.js-header').addClass('headroom--pinned');
    }
  },
  initSal() {
    sal();
  },
  initNavInsert() {
    $('.nav--main .menu-item-has-children .sub-menu').each(function() {
        const menu_item = $(this).closest('li');
        const id = menu_item.attr('id');

        if ($(this).find('.current_page_item').length) {
          menu_item.addClass('current_page_parent_enabled');
          $(this).after(`<div data-current-menu-id="${id}" class="current_page"><li style="padding: 5px 0px">${$(this).find('.current_page_item').html()}</li></div>`)
        }
      })
},
  initAccordion() {
    if(document.querySelectorAll('.accordion-container').length > 0){
      const accordions = Array.from(document.querySelectorAll('.accordion-container'));
        if (accordions) {
          let count = 0;
          let id = null;

          accordions.forEach(item => {
            id = 'accordion-container-' + count;
            item.id = id;

            new Accordion('#accordion-container-' + count, {
             closeOthers: false,
            });
            count++;
          });
        }
    }
  },
  initDropdown(){
    $('select').prettyDropdown({
      width: '100%',
      customClass: 'arrow-custom',
      selectedMarker: '',

    });
  },
  initHomePageAnimation() {

    if(wp_php_vars.isFrontPage) {

      let pradoLogo = $('.header__content .logo.js-logo-main');
      let pradoNav = $('.header__content .nav__list');
      let pradoText = $('.home .hero__content');
      let pradoNavTopOverlay = $('.header-style');
      let imgMaskOverlay = $('.hero__bg--img-mask-overlay');
      let imgMaskWhiteOverlay = $('.hero__bg--img-mask-white-overlay');
      let pradoHeroImgGradientOverlay = $('.hero__bg--img-gradient-overlay');
      let pradoHeroFirstImg = $('.loadingImage.hero__bg--image');

      let pradoHomeAnimationRan = getCookie('pradoHomePageAnimation');
      // let pradoHomeAnimationRan = 1;
      // document.cookie = 'pradoHomePageAnimation=0';
      if(pradoHomeAnimationRan == 1 ){
        $("body").removeClass("preload");
        $('html, body').removeClass('homepage-animation--hide');
        imgMaskOverlay.hide();
        $('.hero__slides .home-white-foreground').hide();
        imgMaskWhiteOverlay.hide();
        // pradoHeroFirstImg.hide();
        $('.home .nav--mobile').css('display', 'block');
        $('.home .hamburger').css('opacity', 1);
        pradoText.css({opacity: 1});
        pradoHeroImgGradientOverlay.css('opacity', 0.55);
        setTimeout(() => {
          $('.hero .arrow-anchor').addClass('arrow-anchor-show');
        }, 1000);
        this.initSliders();
        // Iniate autoplay
        $('.js-slides').slick('slickSetOption', {
          autoplay: true
        }, true);
        $(window).scroll(() => this.heroScrollTrack() )
      } else {
        window.onload = () => {
          window.scrollTo(0, 0);
          $("body").removeClass("preload");
          this.initSliders();

          $('.hero__bg').ready(() => {
            setTimeout(() => {
              $('.home-white-foreground').css('opacity', 0);

              setTimeout(() => {
                pradoHeroFirstImg.css({opacity: 0});
              }, 1200);

              setTimeout(() => {
                imgMaskWhiteOverlay.css({opacity: 0});
              }, 2000);

              setTimeout(() => {
                animateHome();
              }, 3000);

            }, 500);
          });
        }
      }

      const animateHome = () => {
        imgMaskOverlay.addClass('last-fadeout');
        pradoHeroImgGradientOverlay.css({opacity: 0.55});

        setTimeout(() => {
          console.log('start');
          pradoNavTopOverlay.addClass('header-style--home-show');
          pradoLogo.css({ opacity: 1});
          pradoNav.css({ opacity: 1});
          $('.home .nav--mobile').css('display', 'block');
          $('.home .hamburger').css('opacity', 1);
        }, 1200);

        setTimeout(() => {
          pradoText.css({opacity: 1});
          $('.hero .arrow-anchor').addClass('arrow-anchor-show');
        }, 2000);

        setTimeout(() => {
          $('html, body').removeClass('homepage-animation--hide');

          // Iniate autoplay after all of home's animations have been finished
          $('.js-slides').slick('slickSetOption', {
            autoplay: true
          }, true);
        }, 3500);

        //set 2hr cookie
        var d = new Date();
        var numOfDays = 0.0833333333333333; // number of days in hrs, equals to 2 hrs
        var cname = 'pradoHomePageAnimation';
        var cvalue = 1;
        d.setTime(d.getTime() + (numOfDays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

         // Enable hero scrolltrack
         $(window).scroll(() => this.heroScrollTrack() )
      }

      function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
    }
  },
  map() {

    function highlight_group(group) {
      if (!group) {
        $("[data-group]").removeClass("faded");
        $(".project-map__image").removeClass("is-active");
        return false;
      }
      $(".project-map__image").addClass("is-active");
      $("[data-group]").addClass("faded");
      $(`[data-group="${group}"]`).removeClass("faded");
    }

    var ExtendedMarker = L.Marker.extend({
      bindPopup: function (htmlContent, options) {
        if (options && options.showOnMouseOver) {
          // call the super method
          L.Marker.prototype.bindPopup.apply(this, [htmlContent, options]);

          // unbind the click event
          const thisExtendedMarker = this;

          if ($(this.$win).width() >= 960) {
            thisExtendedMarker.on("click", this._gotoLink, this);
          }

          // bind to mouse over
          thisExtendedMarker.on(
            "mouseover touchstart",
            function (e) {
              // get the element that the mouse hovered onto
              var target =
                e.originalEvent.fromElement || e.originalEvent.relatedTarget;
              var parent = this._getParent(target, "leaflet-popup");
              //var group = target.dataset.GroupMarker;

              // check to see if the element is a popup, and if it is this marker's popup
              if (parent == this._popup._container) return true;

              const group = $(this._popup._content).data("groupMarker");

              // show the popup
              if (group) {
                this.openPopup();
                this._fadeIcons();
                this._addLayer(options.layer, options.map);
                highlight_group(group);
              }
              this.on("click", this._gotoLink, this);
            },
            this
          );

          // and mouse out
          this.on(
            "mouseout touchend",
            function (e) {
              // get the element that the mouse hovered onto
              var target =
                e.originalEvent.toElement || e.originalEvent.relatedTarget;

              // check to see if the element is a popup
              if (this._getParent(target, "leaflet-popup")) {
                L.DomEvent.on(
                  this._popup._container,
                  "mouseout",
                  this._popupMouseOut,
                  this
                );
                return true;
              }

              // hide the popup
              this.closePopup();
              this._resetIcons();
              this._removeLayer(options.layer);
              highlight_group(false);
            },
            this
          );
        }
      },

      _addLayer: function (layer, map) {
        layer.addTo(map);
      },

      _removeLayer: function (layer) {
        layer.remove();
      },

      _fadeIcons: function () {
        const icons = document.querySelectorAll(".leaflet-marker-icon");
        for (var i = 0; i < icons.length; ++i) {
          icons[i].classList.add("is-faded");
        }
        this._icon.classList.remove("is-faded");
      },

      _resetIcons: function () {
        const icons = document.querySelectorAll(".leaflet-marker-icon");
        for (var i = 0; i < icons.length; ++i) {
          icons[i].classList.remove("is-faded");
        }
      },

      _gotoLink: function (e) {
        window.location.href = this.options.customHref;
      },

      _popupMouseOut: function (e) {
        // detach the event
        L.DomEvent.off(this._popup, "mouseout", this._popupMouseOut, this);

        // get the element that the mouse hovered onto
        var target = e.toElement || e.relatedTarget;

        // check to see if the element is a popup
        if (this._getParent(target, "leaflet-popup")) return true;

        // check to see if the marker was hovered back onto
        if (target == this._icon) return true;

        // hide the popup
        this.closePopup();
      },

      _getParent: function (element, className) {
        var parent = element.parentNode;

        while (parent != null) {
          if (parent.className && L.DomUtil.hasClass(parent, className))
            return parent;

          parent = parent.parentNode;
        }

        return false;
      },
    });

    const section_height = $(".project-map--section").innerHeight();

    $("#map").css("height", section_height);



    const map = L.map("map", {
      zoomControl: true,
      scrollWheelZoom: false,
      attributionControl: false,
    }).setView([37.790251927933284, -122.41354401588943], 13.9);

    // useful to get current lat and longitude.
    // map.on('click', function(e) {
    //   alert("Lat, Lon : " + e.latlng.lat + ", " + e.latlng.lng)
    // });

    const svgElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    const svgElementBounds = [
      [85, -180],
      [-85, 180],
    ];
    svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgElement.setAttribute("viewBox", "0 0 1920 1080");
    svgElement.innerHTML =
      '<rect x="0.5" y="0.5" fill="#CEE2F5" fill-opacity="0.7" width="1919" height="1079"/>';

    const layer = L.svgOverlay(svgElement, svgElementBounds).addTo(map);

    const defaultIcon = L.icon({
      iconUrl: "/wp-content/themes/_pradogrp/img/dot.svg",
      iconSize: [10, 10],
      iconAnchor: [0, 0],
    });

    const staticIcon = L.icon({
      iconUrl: "/wp-content/themes/_pradogrp/img/dot-light.svg",
      iconSize: [8, 8],
      iconAnchor: [0, 0],
    });

    L.tileLayer(
      "https://api.mapbox.com/styles/v1/prado-group/ck6s99w8y0qaw1iofkxaks5eo/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHJhZG8tZ3JvdXAiLCJhIjoiY2s2czh0eWR2MGVlNzNscDVpcDA4dmZyOCJ9.ykin23O3k9bE8YZcgL-55w",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: "prado-group",
      }
    ).addTo(map);

    layer.remove();

    const arrayOfMarkers = [];

    $(".map-group").each(function () {
      const $this = $(this);
      const plots = $this.data("plots");
      const thisIcon = $this.data("group") ? defaultIcon : staticIcon;

      plots.forEach(function (item) {
        if (item && item.lat) {
          var marker = new ExtendedMarker([item.lat, item.lng], {
            icon: thisIcon,
            customHref: item.href,
          }).addTo(map);
  
          marker.bindPopup(
            `<a href="${plots[0]['href']}"<p data-group-marker="${$this.data("group")}">${item.project}</p>`,
            {
              showOnMouseOver: $this.data("group") ? true : false,
              layer,
              map,
            }
          );
  
        arrayOfMarkers.push(marker);
        }
      });
    });

    var groupOfMarkers = new L.featureGroup(arrayOfMarkers);
    // map.fitBounds(groupOfMarkers.getBounds()); // Uncomment to allow to show all the dots again.

    var myCircle = new L.CircleMarker(new L.LatLng(50.924480, 10.758276), 10).addTo(map); // just instantiating a CircleMarker to disallow double click zooming.
    map.doubleClickZoom.disable();

  },

  mobileSubMenuData(event) {
    $('.nav--mobile .sub-menu').each(function() {
      const height = $(this).innerHeight();
      $(this).attr('data-height', height)

    })
  },
  showMobileNav(event) {
    event.preventDefault();
    this.mobileSubMenuData();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    this.$logo_active.toggleClass('mobile-active');
    this.$logo.toggleClass('mobile-inactive');
    if ( this.$mobileNav.hasClass('is-active') ) {
      $('html').css('overflow', 'hidden');
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      $('html').css('overflow', 'auto');
      this.$hamburger.removeAttr("aria-pressed");
    }
  },

  hoverTileWhenOnViewport() {



    let fullWidthTile = document.querySelectorAll('.full-width-tile');
    let twoColTile = document.querySelectorAll('.two-col-tile');
    let arrayOfItems = [fullWidthTile, twoColTile];

    const tileShowDescriptionOnViewport = element => {

      let timeoutSet = false;
      let timeoutID;

      const showOverlayDesc = () => {
        if (isInViewport(element)) {
          if (!timeoutSet) {
            timeoutID = setTimeout(() => {
              $(element).find('[class*=--hover-desc]').css({opacity: 1});
            }, 0);
            timeoutSet = true; // flag set to true to prevent multiple timeouts from being created.
          }
        }else {
          clearTimeout(timeoutID); //clear timeout when out of viewport
          timeoutSet = false; //set flag to false to allow to create a new timeout
          $(element).find('[class*=--hover-desc]').css({opacity: 0});
        }
      }

      $(document).find('.lazyload').on('lazyloaded', () => {
        once(showOverlayDesc());
      });

      document.addEventListener('scroll', function (event) {
        showOverlayDesc();
       });
    }

    if( $(window).width() >= 700 ) {
      if (fullWidthTile.length > 0 || twoColTile.length > 0) {
        arrayOfItems.forEach(tileItem => {
          tileItem.forEach(element => {
            tileShowDescriptionOnViewport(element);
          });
        });
      }
    }
  },
  deferLoadingUntilLazyloaded() {
    if($(document).find('.lazyload').length) {
      $(document).find('.lazyload').on('lazyloaded', () =>{
        $(document).find('.prof-overlay').css('opacity', 0.55);
        $(document).find('.proj_detail--img-gradient-overlay').css('opacity', 1);
        $(document).find('.full-width-tile--overlay').css('opacity', 0.55);
        if(!wp_php_vars.isFrontPage) {
          $(document).find('.hero__bg--img-gradient-overlay').css('opacity', 0.55);
          $(document).find('header.js-header').css('opacity', 1);
        }
      });
    }else {
      $(document).find('header.js-header').css('opacity', 1);
    }
  },
  handleModalGridOverlay () {
    const modalGridItems = $('.js-modal-grid-col');

    modalGridItems.each( (idx, el) => {
      $(el).find('img').on('lazyloaded', () => {
        $(el).find('.modal__item--overlay').height($(el).find('img').innerHeight());
      });
    });

    this.$win.on('resize', _.debounce(function() {
      console.log('resizing');
      modalGridItems.each( (idx, el) => {
        $(el).find('.modal__item--overlay').height($(el).find('img').innerHeight());
      });
    }, 100));
  }
};

document.addEventListener('DOMContentLoaded', function () {
  Mangrove.init();
});

